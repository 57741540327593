<div class="container">
  <section
    *ngIf="produto && produto.avaliacao && produto.avaliacao.total"
    class="avaliacoes-clientes"
  >
    <h2>Avaliação dos clientes</h2>

    <crtz-avaliacao-nota [avaliacao]="produto.avaliacao"> </crtz-avaliacao-nota>
  </section>

  <section *ngIf="avaliacoes.length">
    <div class="avaliacoes">
      <ng-container *ngFor="let avaliacao of avaliacoes; trackBy: trackByAvaliacaoUsuario">
        <crtz-avaliacao-review [avaliacao]="avaliacao"></crtz-avaliacao-review>
      </ng-container>
      <crtz-avaliacao-review-loader *ngIf="subscriptionFinished"></crtz-avaliacao-review-loader>
    </div>
    <div
      class="mais-avaliacoes"
      *ngIf="!subscriptionFinished"
    >
      <button
        *ngIf="hasMoreAvaliacoes()"
        (click)="requestMoreAvaliacoes()"
        class="qa-landing-page-button-request-more-click button button--selected"
        type="button"
      >Ver mais avaliações</button>
    </div>
  </section>
</div>
