<section>
  <div class="header">
    <button
      type="button"
      (click)="close()"
    >
      <em class="icon icon-close"></em>
    </button>
  </div>

  <div class="video-container">
    <crtz-video-youtube-player
      *ngIf="video"
      [video]="video"
    ></crtz-video-youtube-player>
  </div>
</section>
