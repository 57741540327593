import { Injectable } from '@angular/core';
import { Produto } from '@domain/produto/produto.model';
import { DeserializedDataValidationService } from '@shared/common-validation/deserialized-data-validation.service';
import { array, createCheckers, iface, ITypeSuite, opt, union } from 'ts-interface-checker';

@Injectable()
export class ProdutoChecker {
  private Produto = iface([], {
    id: 'number',
    sku: 'number',
    nome: 'string',
    descricao: 'string',
    skus: array('ProdutoSku'),
    fichaTecnica: array('ProdutoFichaTecnica'),
    videos: array('ProdutoVideo'),
    material: union('string', 'null'),
    categoria: 'ProdutoCategoria'
  });

  private ProdutoSku = iface([], {
    sku: 'number',
    nome: 'string',
    dimensoes: opt('ProdutoDimensoes'),
    imagemPrincipal: 'ProdutoImagemVariacoes',
    imagens: array('ProdutoImagemVariacoes')
  });

  private ProdutoDimensoes = iface([], {
    altura: union('number', 'null'),
    largura: union('number', 'null'),
    comprimento: union('number', 'null'),
    peso: union('number', 'null')
  });

  private ProdutoImagemVariacoes = iface([], {
    THUMBNAIL: opt('ProdutoImagem'),
    MEDIA: opt('ProdutoImagem'),
    PRINCIPAL: opt('ProdutoImagem'),
    ZOOM: opt('ProdutoImagem')
  });

  private ProdutoImagem = iface([], {
    altura: 'number',
    largura: 'number',
    imagem: 'string'
  });

  private ProdutoFichaTecnica = iface([], {
    nome: 'string',
    especificacoes: array('ProdutoFichaTecnicaEspecificacao')
  });

  private ProdutoFichaTecnicaEspecificacao = iface([], {
    nome: 'string',
    valor: 'string'
  });

  private ProdutoVideo = iface([], {
    videoPath: 'string'
  });

  private ProdutoCategoria = iface([], {
    id: 'number',
    nome: 'string'
  });

  private validations: ITypeSuite = {
    Produto: this.Produto,
    ProdutoSku: this.ProdutoSku,
    ProdutoDimensoes: this.ProdutoDimensoes,
    ProdutoImagemVariacoes: this.ProdutoImagemVariacoes,
    ProdutoImagem: this.ProdutoImagem,
    ProdutoFichaTecnica: this.ProdutoFichaTecnica,
    ProdutoFichaTecnicaEspecificacao: this.ProdutoFichaTecnicaEspecificacao,
    ProdutoVideo: this.ProdutoVideo,
    ProdutoCategoria: this.ProdutoCategoria
  };

  private checker = createCheckers(this.validations);

  constructor(
    private deserializedDataValidationService: DeserializedDataValidationService
  ) { }

  validateProduto(produto: Produto): Produto {
    const checker = this.checker.Produto;

    this.deserializedDataValidationService.checker(produto, checker);

    return produto;
  }
}
