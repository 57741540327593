import { Component, ComponentFactoryResolver, HostBinding, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { MainErrorObservable } from '@shared/error/main-error.observable';
import { ModalBuilder } from '../modal.builder';
import { ModalDirective } from '../modal.directive';

@Component({
  selector: 'crtz-modal-figital',
  templateUrl: './modal-figital.component.html',
  styleUrls: ['./modal-figital.component.scss']
})
export class ModalFigitalComponent extends ModalDirective {

  tagNameElement = 'crtz-modal-figital';
  modalInject$ = ModalBuilder.componentInject$;

  @ViewChild('modalContainer', { read: ViewContainerRef })
  container: ViewContainerRef | null = null;

  @HostBinding('style.display')
  get hostDisplay(): 'block' | 'none' {
    return this.isOpen ? 'block' : 'none';
  }

  constructor(
    protected error$: MainErrorObservable,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    super();
  }

  @HostBinding('class')
  get hostClasses(): string {
    return this.getClasses();
  }

  @HostListener('document:keydown.escape')
  closeModal(): void {
    this.isVisible = false;

    // Para que a animação de esconder o Modal seja visível ao usuário é necessário atrasar um pouco o remoção do HTML
    const MILLISECONDS_TO_HIDE = 300;

    //  Este setTimeout atende uma demanda de animação
    // eslint-disable-next-line
    setTimeout(() => {
      super.closeModal();
    }, MILLISECONDS_TO_HIDE);

  }
}
