<!-- <header>

  <meta
    property="og:image:secure_url"
    content="casasbahia/static/images/seoblog.jpg"
  >

  <meta
    name="title"
    content="Smart TV 50&quot; UHD 4K Samsung | Casas Bahia"
  />
  <meta
    name="description"
    content="Smart TV 50&quot; UHD em até 24x* no site ou aplicativo. Smart TV com entrega rápida. Compre com o Carnê Digital Casas Bahia"
  />
  <meta
    name="Abstract"
    content="Smart TV 50&quot; UHD em até 24x* no site ou aplicativo. Smart TV com entrega rápida. Compre com o Carnê Digital Casas Bahia"
  />
  <link
    rel="shortcut icon"
    href="https://www.casasbahia-imagens.com.br/App_Themes/CasasBahia/favicon.ico"
    sizes="16x16"
  />
  <link
    rel="preload"
    as="script"
    href="https://www.casasbahia.com.br/resources/1b15128895fbd8dc9bcf26525a47a5432b262531f1d5a"
  />
  <link
    rel="preload"
    as="image"
    href="https://imgs.casasbahia.com.br/55060824/1g.jpg?imwidth=500"
  />

  <link
    rel="preload"
    as="image"
    href="https://imgs.casasbahia.com.br/55060824/1g.jpg?imwidth=500"
  >
</header> -->


<section class="container">
  <ng-container *ngIf="isLoadingProduto(); else detalheProduto">
    <crtz-produto-detalhe-loader></crtz-produto-detalhe-loader>
  </ng-container>

  <ng-template #detalheProduto>
    <main *ngIf="produto && produtoSku; else produtoNotFound">
      <section class="informacoes-produto">
        <div>
          <crtz-carousel-imagens-produto
            class="carousel-container"
            [imagens]="produtoSku.imagens"
            [videos]="produto.videos"
            [containerWidth]="440"
            [imagemPrincipal]="imagemPrincipal"
            [carouselItemWidth]="85"
            [openDetalheImagemPrincipal]="true"
          ></crtz-carousel-imagens-produto>

          <ng-container *ngIf="!isMobile()">
            <ng-container [ngTemplateOutlet]="dimensoes_moveis"></ng-container>
          </ng-container>
        </div>

        <div>
          <ng-container *ngIf="!isMobile()">
            <ng-container [ngTemplateOutlet]="avaliacao_resumo"></ng-container>
          </ng-container>

          <h1
            class="title-produto"
            [innerHtml]="produto.nome | htmlSafe"
          ></h1>

          <ng-container *ngIf="isMobile()">
            <ng-container [ngTemplateOutlet]="dimensoes_moveis"></ng-container>
          </ng-container>

          <section
            class="equivalencias"
            *ngIf="hasEquivalencias(produto)"
          >
            <label for="equivalencia-tipo">Selecione</label>
            <select
              class="qa-landing-page-equivalenca-select"
              id="equivalencia-tipo"
              (change)="onChangeProdutoSku($event.target.value)"
            >
              <option
                *ngFor="let produtoSku of produto.skus; trackBy: trackByFn"
                [value]="produtoSku.sku"
              >{{produtoSku.nome}}</option>
            </select>
          </section>

          <ng-container *ngIf="isMobile()">
            <ng-container [ngTemplateOutlet]="avaliacao_resumo"></ng-container>
          </ng-container>

          <section class="detalhe detalhe__descricao">
            <div
              (click)="toggleDetalhes = !toggleDetalhes"
              class="detalhe__title"
            >
              <h2>Descrição do produto</h2>
              <svg
                class="icon {{toggleDetalhes ? 'to-up' : 'to-bottom'}}"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <use xlink:href="./assets/images/svg-sprite-icones.svg#arrow" />
              </svg>
            </div>
            <div
              class="detalhe__content"
              *ngIf="toggleDetalhes"
            >
              <div [innerHTML]="produto.descricao | htmlSafe"></div>
            </div>
          </section>

          <section
            *ngFor="let fichaTecnica of produto.fichaTecnica; trackBy: trackByFn"
            class="detalhe detalhe__ficha-tecnica"
          >
            <div
              (click)="fichaTecnica.toggleFichaTecnica = !fichaTecnica.toggleFichaTecnica"
              class="detalhe__title"
            >
              <h2>{{fichaTecnica.nome}}</h2>
              <svg
                class="icon {{fichaTecnica.toggleFichaTecnica ? 'to-up' : 'to-bottom'}}"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <use xlink:href="./assets/images/svg-sprite-icones.svg#arrow" />
              </svg>
            </div>
            <div
              class="detalhe__content"
              *ngIf="fichaTecnica.toggleFichaTecnica"
            >
              <ul>
                <li *ngFor="let especificacao of fichaTecnica.especificacoes; trackBy: trackByFn">
                  <h3>{{especificacao.nome}}</h3>
                  <div [innerHTML]="especificacao.valor | htmlSafe"></div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </section>

      <section
        class="container-reviews"
        *ngIf="produto?.avaliacao"
      >
        <crtz-produto-avaliacoes-review [produto]="produto"></crtz-produto-avaliacoes-review>
      </section>
    </main>
  </ng-template>

  <ng-template #produtoNotFound>
    <crtz-not-found></crtz-not-found>
  </ng-template>
</section>

<ng-template #dimensoes_moveis>
  <section
    *ngIf="produtoSku && produto && isTipoMoveis() && possuiInformacoesDimensoes()"
    class="dimensoes"
  >
    <h2>Dimensões do produto</h2>

    <div
      class="dimensoes__detalhada"
      *ngIf="produtoSku.dimensoes"
    >
      <div class="dimensoes__detalhada__column">
        <div *ngIf="produtoSku.dimensoes.altura">
          <img
            src="assets/images/icon-altura.svg"
            alt="Altura"
            aria-hidden="true"
          >
          <div>
            <strong>Altura:</strong>
            <span>{{produtoSku.dimensoes.altura | centimetros}}</span>
          </div>
        </div>

        <div *ngIf="produtoSku.dimensoes.comprimento">
          <img
            src="assets/images/icon-profundidade.svg"
            alt="Profundidade"
            aria-hidden="true"
          >
          <div>
            <strong>Profundidade:</strong>
            <span>{{produtoSku.dimensoes.comprimento | centimetros}}</span>
          </div>
        </div>

      </div>
      <div class="dimensoes__detalhada__column">
        <div *ngIf="produtoSku.dimensoes.largura">
          <img
            src="assets/images/icon-largura.svg"
            alt="Largura"
            aria-hidden="true"
          >
          <div>
            <strong>Largura:</strong>
            <span>{{produtoSku.dimensoes.largura | centimetros}}</span>
          </div>
        </div>

        <div *ngIf="produto.material">
          <img
            src="assets/images/icon-material.svg"
            alt="Material"
            aria-hidden="true"
          >
          <div>
            <strong>Material:</strong>
            <span>{{produto.material}}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #avaliacao_resumo>
  <section class="avaliacao-resumo">
    <crtz-star-rating [rating]="produto?.avaliacao?.nota"></crtz-star-rating>

    <button
      *ngIf="hasShareFunction"
      (click)="shareLinkProduto()"
      class="qa-landing-page-button-share-link-click button button--primary"
      type="button"
    >
      <svg
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <use xlink:href="assets/images/svg-sprite-icones.svg#share" />
      </svg>
    </button>
  </section>
</ng-template>
