<section
  *ngIf="error"
  [class.is-error]="isError(error)"
  [class.is-warning]="isWarning(error)"
>
  <div *ngFor="let mensagem of error?.messages; trackBy: trackByFn">
    <p>{{mensagem}}</p>
    <button
      type="button"
      class="qa-landing-page-button-error-modal-click"
      (click)="closeError()"
    >Ok</button>
  </div>
</section>
