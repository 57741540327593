import { Injectable } from '@angular/core';
import { Calc } from 'calc-js';
import { Subscription } from 'rxjs';

@Injectable()
export class ProdutoAvaliacoesService {

  private readonly DOUBLE_SIZE = 2;

  isSubscriptionRequesting(subscription: Subscription | null): boolean {
    if (!subscription) {
      return false;
    }

    if (subscription.closed) {
      return false;
    }

    return true;
  }

  scrollIsReachingEnd(
    scrollReached: number, documentoScrollHeight: number, windowHeight: number
  ): boolean {
    const pixelsNearEnd = new Calc(documentoScrollHeight)
      .minus(windowHeight)
      .minus(scrollReached)
      .finish();

    const loadPageArea = new Calc(windowHeight).multiply(this.DOUBLE_SIZE).finish();
    if (pixelsNearEnd < loadPageArea) {
      return true;
    }

    return false;
  }
}
