<div
  class="container"
  [ngClass]="isVisible ? 'container--visible' : 'container--hidden'"
>
  <section
    class="modal"
    [ngClass]="isVisible ? 'modal--visible' : 'modal--hidden'"
  >
    <button
      class="modal__button"
      (click)="closeModal()"
    >
      <em class="icon icon-close"></em>
    </button>
    <div class="modal__content">
      <ng-template #modalContainer></ng-template>
    </div>
  </section>
</div>
