import { Pipe, PipeTransform } from '@angular/core';
import { Calc } from 'calc-js';
import { NumberUtilService } from '../util/number-util.service';

@Pipe({
  name: 'centimetros'
})
export class CentimetroPipe implements PipeTransform {

  private readonly UM_METRO = 100;
  private readonly CENTIMETROS_MINIMO_DECIMAIS = 2;

  constructor(
    private numberUtil: NumberUtilService
  ) { }

  transform(centimetros: number): string {
    if (centimetros < this.UM_METRO) {
      const centrimetrosZerofilled = this.numberUtil.zerofillAfterDot(
        centimetros, this.CENTIMETROS_MINIMO_DECIMAIS
      );
      return `${centrimetrosZerofilled} cm`;
    } else {
      const metrosZerofilled = this.numberUtil.zerofillAfterDot(
        Calc.divide(centimetros, this.UM_METRO),
        this.CENTIMETROS_MINIMO_DECIMAIS
      );

      return `${metrosZerofilled} m`;
    }
  }
}
