import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilModule } from '../util/util.module';
import { CentimetroPipe } from './centimetro.pipe';
import { GramasPipe } from './gramas.pipe';

@NgModule({
  declarations: [
    CentimetroPipe,
    GramasPipe
  ],
  exports: [
    CentimetroPipe,
    GramasPipe
  ],
  imports: [
    CommonModule,
    UtilModule
  ]
})
export class DimensoesModule { }
