import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Produto } from '@domain/produto/produto.model';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProdutoChecker } from './produto.checker';

/**
 * Serviço de integração com API, é sua responsabilidade:
 * I - Integrar o sistema com uma estrutura externa para coleta de dados;
 * II - Garantir que os dados entregues estão conforme o esperado (validação);
 */
@Injectable()
export class ProdutoApi {

  constructor(
    private httpClient: HttpClient,
    private produtoChecker: ProdutoChecker
  ) { }

  getProduto(sku: number): Observable<Produto> {
    const path = `${environment.server}online/v1/produto/${sku}`;
    const params = new HttpParams()
      .set('bandeira', environment.bandeira);

    return this.httpClient.get<Produto>(path, { params })
      .pipe(map(produto => this.produtoChecker.validateProduto(produto)));
  }

}
