import { Injectable } from '@angular/core';

/**
 * Este serviço tem como excluivo propósito a redução de complexidade ciclomática
 * de outros serviços.
 * Estou centralizando o encapsulamento de pequenas validações de tipos de dados
 * neste serviço, mas a verdade é que estas validações não tem complexidade justificável
 * para que sejam realmente centralizadas.
 */
@Injectable({
  providedIn: 'root'
})
export class UtilTypeGuard {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isString(anything: any): anything is string {
    return typeof anything === 'string';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isNumber(anything: any): anything is number {
    return typeof anything === 'number';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isBoolean(anything: any): anything is boolean {
    return typeof anything === 'boolean';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isNativeObject(anything: any): anything is Object {
    return anything && anything.constructor === Object || false;
  }

}
