import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilTypeGuard } from './util.type-guard';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    UtilTypeGuard
  ]
})
export class TypeGuardUtilModule { }
