import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StarRatingModule } from '@shared/star-rating/star-rating.module';
import { AvaliacaoReviewComponent } from './avaliacao-review.component';

@NgModule({
  declarations: [
    AvaliacaoReviewComponent,
  ],
  imports: [
    CommonModule,
    StarRatingModule
  ],
  exports: [
    AvaliacaoReviewComponent
  ]
})
export class AvaliacaoReviewModule { }
