import { Component } from '@angular/core';
import { Bandeira } from '@domain/bandeira.enum';
import { environment } from '@environment';

/**
 * São responsabilidades deste quanto componente:
 *  I - Cuidar do ciclo de vida do componente e emissão de eventos;
 *  II - Repassagem de dados para o template, incluindo assim a inscrição em observables;
 */
@Component({
  selector: 'crtz-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  readonly BANDEIRA_CASAS_BAHIA = Bandeira.CASAS_BAHIA;
  readonly BANDEIRA_PONTO = Bandeira.PONTO;
  readonly BANDEIRA_EXTRA = Bandeira.EXTRA;

  bandeira = environment.bandeira;
}
