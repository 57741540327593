import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberUtilService {

  zerofillAfterDot(meZerofila: number, filaEleEsseTanto: number): string {
    const numeroAsString = String(meZerofila);
    const inteiros = numeroAsString.replace(/\.\d*$/, '');
    let decimais = numeroAsString.replace(/^\d*(\.|$)/, '');
    decimais = `${decimais}00`.substr(0, filaEleEsseTanto);

    return `${inteiros},${decimais}`;
  }
}
