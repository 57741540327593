import { Component, Input } from '@angular/core';
import { AvaliacaoUsuario } from '@domain/avaliacao/avaliacao-usuario.model';

@Component({
  selector: 'crtz-avaliacao-review',
  templateUrl: './avaliacao-review.component.html',
  styleUrls: ['./avaliacao-review.component.scss']
})
export class AvaliacaoReviewComponent {

  @Input()
  avaliacao: AvaliacaoUsuario | null = null;

}
