import { Type } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { IModalMetadata } from './modal-metadata.interface';
import { ModalableDirective } from './modalable.directive';

export class ModalBuilder<EntryType, ReturnType> {

  private static componentInjectSubject = new Subject<IModalMetadata<unknown, unknown>>();
  static componentInject$ = ModalBuilder.componentInjectSubject.asObservable();

  private injectData: EntryType | null = null;
  private cssClasses: string[] = [];
  private router?: Router;

  private subscription = new Subscription();

  constructor(private component: Type<ModalableDirective<EntryType, ReturnType>>) { }

  setData(data: EntryType): ModalBuilder<EntryType, ReturnType> {
    this.injectData = data;
    return this;
  }

  // setInnerStyle(data: { hasDefaultHeader: boolean }): ModalBuilder<EntryType, ReturnType> {
  //   this.injectData = data;
  //   return this;
  // }


  /**
   * Deixa o modal associado a rota, se a rota mudar, ele some.
   */
  setBindToRoute(router: Router): ModalBuilder<EntryType, ReturnType> {
    this.router = router;
    return this;
  }

  setRootCssClasses(classes: string[]): ModalBuilder<EntryType, ReturnType> {
    this.cssClasses = classes;
    return this;
  }

  /**
   * O tipo void sempre deve ser considerado como retorno, isso deve ocorrer por que
   * quando o observable for convertido para promise ele vai fundir o next com o complete
   * e quando não houver retorno de next, por conta de uma fechada forçada do modal, o promise
   * receberá um void
   */
  build(): Observable<ReturnType | void> {
    const response = new Subject<ReturnType>();
    const data = this.injectData as unknown;
    const component = this.component as Type<ModalableDirective<unknown, unknown>>;

    if (this.router) {
      const voidCalle = (): void => void (0);
      //  FIXME: ver uma forma de ignorar alteração de rota para query params
      this.subscription.add(this.router.events
        .pipe(filter(nagivation => nagivation instanceof NavigationStart))
        .pipe(first())
        .subscribe(() => {
          if (!response.closed) {
            response.complete();
          }
        }));

      this.subscription.add(response.subscribe(
        voidCalle, voidCalle,
        () => this.subscription.unsubscribe()
      ));
    } else {
      console.warn(
        `Componente "${component.name}" servido como modal não foi associado a ` +
        'rota e não será removido automaticamente caso a rota seja mudada'
      );
    }

    //  Sendo esta inscrição pública a toda aplicação ela não tem como saber
    //  qual valor de generics foi assumido, uma vez que o generics está associado
    //  diretamente a instância
    ModalBuilder.componentInjectSubject.next({
      component, data, cssClasses: this.cssClasses,
      response: response as Subject<unknown>
    });

    return response.asObservable();
  }
}
