import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SoftwareVersionApi } from './software-version.api';
import { SoftwareVersionService } from './software-version.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    SoftwareVersionService,
    SoftwareVersionApi
  ]
})
export class SoftwareVersionModule { }
