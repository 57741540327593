export enum LogType {
  DEPRECATED = 'DEPRECATED',
  FRONTEND_CRASH_ERROR = 'FRONTEND_CRASH_ERROR',
  BACKEND_ERROR = 'BACKEND_ERROR',
  DEVICE_ERROR = 'DEVICE_ERROR',
  INTEGRATION_INVALID_SCHEMA = 'INTEGRATION_INVALID_SCHEMA',
  CONNECTION_FAILURE = 'CONNECTION_FAILURE',
  BUSSINESS_ERROR = 'BUSSINESS_ERROR',
  NUMERIC_INCONSISTENCY = 'NUMERIC_INCONSISTENCY',
  FRONTEND_LOGICAL_ERROR = 'FRONTEND_LOGICAL_ERROR',
  INFORMATIVE = 'INFORMATIVE',
  HIGHTLIGHTED = 'HIGHTLIGHTED',
  WARNING = 'WARNING'
}
