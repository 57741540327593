import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AvaliacaoCommonModule } from '@shared/avaliacao-common/avaliacao-common.module';
import { CommonValidationModule } from '@shared/common-validation/common-validation.module';
import { LoggerModule } from '@shared/logger/logger.module';
import { ModalModule } from '@shared/modal/modal.module';
import { SoftwareVersionModule } from '@shared/software-version/software-version.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProdutoDetalheModule } from './produto-detalhe/produto-detalhe.module';
import { ErrorModule } from './shared/error/error.module';
import { HeaderModule } from './shared/header/header.module';
import { ProdutoCommonModule } from './shared/produto-common/produto-common.module';
import { TypeGuardUtilModule } from './shared/type-guard-util/type-guard-util.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    //  Modulos arquiteturais
    ErrorModule,
    HttpClientModule,
    TypeGuardUtilModule,
    LoggerModule,
    ModalModule,
    CommonValidationModule,
    SoftwareVersionModule,

    //  Componentes estruturais
    HeaderModule,

    //  entity modules
    ProdutoCommonModule,
    AvaliacaoCommonModule,

    //  Routed module carregado em eager load
    ProdutoDetalheModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
