/**
 * Função responsável por repassar a execução de um processo lógico
 * no tick de processamento posterior
 */
export function nextTick(): Promise<void> {
  return new Promise(resolve => {
    // eslint-disable-next-line
    setTimeout(() => {
      resolve();
    }, 0);
  });
}
