import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Avaliacao } from '@domain/avaliacao/avaliacao.model';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AvaliacaoChecker } from './avaliacao.checker';

@Injectable()
export class AvaliacaoApi {

  private readonly ITENS_POR_PAGINA = 5;

  constructor(
    private httpClient: HttpClient,
    private avaliacaoChecker: AvaliacaoChecker
  ) { }

  getProdutoAvaliacoes(
    produtoId: number, paginaAtual = 0, itensPorPagina = this.ITENS_POR_PAGINA
  ): Observable<{ avaliacao: Avaliacao }> {
    const path = `${environment.server}online/v1/avaliacao/${String(produtoId)}`;

    const params = new HttpParams()
      .set('pagina', String(paginaAtual))
      .set('por_pagina', String(itensPorPagina))
      .set('bandeira', String(environment.bandeira));

    return this.httpClient.get<{ avaliacao: Avaliacao }>(path, { params })
      .pipe(map(wrapper => this.avaliacaoChecker.validateAvaliacao(wrapper)));
  }
}
