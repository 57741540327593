import { HttpResponse } from '@angular/common/http';
import { ICustomErrorAdapter, IErrorNormalized } from 'ecma-error-normalizer';
import { InvalidResultSetException } from '../../http-basic-data-treatment/invalid-result-set.exception';

/**
 * Adapter seguindo as especificações de:
 * https://github.com/lordazzi/ecma-error-normalizer
 *
 * Não conhece o pattern Adapter?
 * https://refactoring.guru/pt-br/design-patterns/adapter
 */
export class InvalidSchemaErrorAdapter implements ICustomErrorAdapter<InvalidResultSetException> {

  name = 'invalid-schema-erro';

  typeCheck(throwable: unknown): throwable is InvalidResultSetException {
    return throwable instanceof InvalidResultSetException;
  }

  normalize(error: InvalidResultSetException): IErrorNormalized {
    return {
      name: '',
      type: 'error',
      messages: ['O sistema se comportou de forma inesperada'],
      technicalMessages: this.getTechinicalMessages(error.request),
      originalInformation: null
    };
  }

  private getTechinicalMessages(response: HttpResponse<unknown>): string[] {
    const mensagens = [
      'Invalid Response Schema',
      `Request: ${response.url}`,
      `Response: (${response.status}, ${response.statusText})`,
      `Body: ${JSON.stringify(response.body)}`
    ];

    return mensagens;
  }
}
