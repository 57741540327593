import { HttpErrorResponse } from '@angular/common/http';
import { ICustomErrorAdapter, IErrorNormalized } from 'ecma-error-normalizer';

/**
 * Adapter seguindo as especificações de:
 * https://github.com/lordazzi/ecma-error-normalizer
 *
 * Não conhece o pattern Adapter?
 * https://refactoring.guru/pt-br/design-patterns/adapter
 */
export class HttpErrorAdapter implements ICustomErrorAdapter<HttpErrorResponse> {

  private readonly MENSAGEM_FALHA_PADRAO = 'O sistema se comportou de forma inesperada';

  name = 'ngx-http-error';

  typeCheck(throwable: unknown): throwable is HttpErrorResponse {
    return throwable instanceof HttpErrorResponse;
  }

  normalize(httpError: HttpErrorResponse): IErrorNormalized {
    return {
      name: '',
      type: 'error',
      messages: this.getMessages(httpError),
      technicalMessages: this.getTechnicalMessages(httpError),
      originalInformation: null
    };
  }

  private getMessages(httpError: HttpErrorResponse): string[] {
    let mensagens = '';

    if (typeof httpError.error === 'string') {
      mensagens = httpError.error;
    } else if (httpError.error?.Mensagens || httpError.error?.Message) {
      mensagens = String(httpError.error?.Mensagens || httpError.error?.Message);
    } else {
      mensagens = this.MENSAGEM_FALHA_PADRAO;
    }

    return mensagens.split('\n');
  }

  private getTechnicalMessages(httpError: HttpErrorResponse): string[] {
    const mensagens = [
      `Request: ${httpError.url}`,
      `Response: (${httpError.status}, ${httpError.statusText})`,
      `Body: ${JSON.stringify(httpError.error)}`
    ];

    return mensagens;
  }
}
