import { Component, Input } from '@angular/core';
import { AvaliacaoUsuario } from '@domain/avaliacao/avaliacao-usuario.model';
import { Produto } from '@domain/produto/produto.model';
import { AvaliacaoApi } from '@shared/avaliacao-common/avaliacao.api';
import { MainErrorObservable } from '@shared/error/main-error.observable';
import { Subscription } from 'rxjs';
import { ProdutoAvaliacoesService } from './produto-avaliacoes.service';

@Component({
  selector: 'crtz-produto-avaliacoes-review',
  templateUrl: './produto-avaliacoes-review.component.html',
  styleUrls: ['./produto-avaliacoes-review.component.scss'],
  providers: [
    ProdutoAvaliacoesService
  ]
})
export class ProdutoAvaliacoesReviewComponent {

  @Input()
  set produto(produto: Produto | null) {
    this.interceptedProduto = produto;
    this.requestMoreAvaliacoes();
  }

  get produto(): Produto | null {
    return this.interceptedProduto;
  }

  private totalAvaliacoes = 0;
  private currentAvaliacoesPage = 0;
  private interceptedProduto: Produto | null = null;
  private subscriptions = new Subscription();
  private subscriptionAvaliacaoRequest: Subscription | null = null;

  avaliacoes: AvaliacaoUsuario[] = [];

  constructor(
    private avaliacaoApi: AvaliacaoApi,
    private error$: MainErrorObservable,
    private service: ProdutoAvaliacoesService
  ) { }

  get subscriptionFinished(): boolean {
    return !this.subscriptionAvaliacaoRequest?.closed;
  }

  requestMoreAvaliacoes(): void {
    if (!this.produto || this.service.isSubscriptionRequesting(this.subscriptionAvaliacaoRequest)) {
      return;
    }

    let newPage = this.currentAvaliacoesPage;
    this.subscriptionAvaliacaoRequest = this.avaliacaoApi
      .getProdutoAvaliacoes(this.produto.id, newPage++)
      .subscribe(
        ({ avaliacao }) => {
          this.totalAvaliacoes = avaliacao.total;
          this.avaliacoes = this.avaliacoes.concat(avaliacao.avaliacoesUsuario);
          this.currentAvaliacoesPage = newPage;
        },
        error => this.error$.next(error)
      );

    this.subscriptions.add(this.subscriptionAvaliacaoRequest);
  }

  hasMoreAvaliacoes(): boolean {
    return this.avaliacoes.length < this.totalAvaliacoes;
  }

  trackByAvaliacaoUsuario(index: number, avaliacaoUsuario: AvaliacaoUsuario): number {
    return avaliacaoUsuario.id;
  }

  trackByFn(index: number): number {
    return index;
  }
}
