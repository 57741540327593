import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SoftwareVersionApi {

  constructor(
    private httpClient: HttpClient
  ) { }

  getPackageJson(): Observable<{ version: string }> {
    const path = 'assets/package.json';
    return this.httpClient.get<{ version: string }>(path);
  }
}
