import { Pipe, PipeTransform } from '@angular/core';
import { ProdutoImagemTipo } from '@domain/produto/produto-imagem-tipo.enum';
import { ProdutoImagemVariacoes } from '@domain/produto/produto-imagem-variacoes.model';
import { IMAGE_NOT_FOUND } from '../image-not-found.const';

@Pipe({
  name: 'produtoImagemTamanho'
})
export class ProdutoImagemTamanhoPipe implements PipeTransform {

  private readonly PRIORIDADE_DOS_TAMANHOS: ProdutoImagemTipo[] = [
    ProdutoImagemTipo.ZOOM,
    ProdutoImagemTipo.PRINCIPAL,
    ProdutoImagemTipo.MEDIA,
    ProdutoImagemTipo.THUMBNAIL
  ];

  transform(imagem: ProdutoImagemVariacoes, tamanhoEsperado: ProdutoImagemTipo): string {
    return this.getImagemFromTamanho(imagem, tamanhoEsperado);
  }

  private getImagemFromTamanho(imagem: ProdutoImagemVariacoes, tamanhoEsperado: ProdutoImagemTipo): string {
    if (imagem[tamanhoEsperado]) {
      return imagem[tamanhoEsperado].imagem;
    }

    const tamanhoAlternativo = Object.keys(imagem).find(key => {
      return this.PRIORIDADE_DOS_TAMANHOS.includes(key as ProdutoImagemTipo);
    });

    if (!tamanhoAlternativo) {
      return IMAGE_NOT_FOUND;
    }

    return this.getImagemFromTamanho(imagem, tamanhoAlternativo as ProdutoImagemTipo);
  }

}
