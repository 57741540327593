import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule as FigitalModalModule } from '@jornada-figital/core-ui';
import { ModalFigitalComponent } from './modal-figital/modal-figital.component';
import { ModalService } from './modal.service';

@NgModule({
  declarations: [
    ModalFigitalComponent,
  ],
  imports: [
    CommonModule,
    FigitalModalModule
  ],
  exports: [
    ModalFigitalComponent
  ],
  providers: [
    ModalService
  ]
})
export class ModalModule { }
