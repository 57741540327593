import { Pipe, PipeTransform } from '@angular/core';
import { ProdutoAvaliacoes } from '@domain/produto/produto-avaliacoes.model';

@Pipe({
  name: 'produtoQuantidadeAvaliacoes'
})
export class ProdutoQuantidadeAvaliacoesPipe implements PipeTransform {

  transform(produtoAvaliacoes: ProdutoAvaliacoes | null): string {
    if (produtoAvaliacoes && produtoAvaliacoes.total !== undefined) {
      if (produtoAvaliacoes.total === 1) {
        return `${produtoAvaliacoes.total} avaliação`;
      } else {
        return `${produtoAvaliacoes.total} avaliações`;
      }
    }

    return '';
  }

}
