import { CommonErrorAdapter, ErrorConverter, NormalizedErrorAdapter } from 'ecma-error-normalizer';
import { BusinesErrorAdapter } from './adapter/business-error.adapter';
import { HttpErrorAdapter } from './adapter/http-error.adapter';
import { InvalidSchemaErrorAdapter } from './adapter/invalid-schema-error.adapter';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const errorConverterFactory = () => new ErrorConverter([
  NormalizedErrorAdapter,
  CommonErrorAdapter.setDefaultMessage('Ocorreu um erro inesperado'),
  HttpErrorAdapter,
  InvalidSchemaErrorAdapter,
  BusinesErrorAdapter
]);
