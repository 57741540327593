import { Injectable } from '@angular/core';
import { LoggerService } from '@shared/logger/logger.service';
import { Checker } from 'ts-interface-checker';

/**
 * Serviço de validação de dados deserializados
 */
@Injectable()
export class DeserializedDataValidationService {

  constructor(
    private logger: LoggerService
  ) { }

  /**
   * Realiza a validação do formato de um objeto conforme o checker informado.
   * Caso o objeto não satisfaça a interface do checker, a excessão padrão
   * é tratada e o erro é simplesmente passado para o serviço de log.
   *
   * @param deserializedData Objeto a ser analisado pelo checker
   * @param checker Checker que será utilizado para fazer a análise
   * @returns Sempre retorna o próprio objeto passado por parâmetro, satisfazendo ou não as condições do checker
   */
  checker<T>(deserializedData: T, checker: Checker): T {
    try {
      checker.check(deserializedData);
    } catch (e) {
      this.logger.logInvalidSchema(e);
    }
    return deserializedData;
  }

}
