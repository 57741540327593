import { ICustomErrorAdapter, IErrorNormalized } from 'ecma-error-normalizer';
import { BusinessException } from './../business.exception';

/**
 * Adapter seguindo as especificações de:
 * https://github.com/lordazzi/ecma-error-normalizer
 *
 * Não conhece o pattern Adapter?
 * https://refactoring.guru/pt-br/design-patterns/adapter
 */
export class BusinesErrorAdapter implements ICustomErrorAdapter<BusinessException> {

  name = 'business-erro';

  typeCheck(throwable: unknown): throwable is BusinessException {
    return throwable instanceof BusinessException;
  }

  normalize(error: BusinessException): IErrorNormalized {
    return {
      name: '',
      type: 'business',
      messages: error.mensagens,
      technicalMessages: [],
      originalInformation: null
    };
  }
}
