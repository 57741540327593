import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvaliacaoApi } from './avaliacao.api';
import { AvaliacaoChecker } from './avaliacao.checker';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    AvaliacaoApi,
    AvaliacaoChecker
  ]
})
export class AvaliacaoCommonModule { }
