import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Avaliacao } from '@domain/avaliacao/avaliacao.model';
import { ProdutoImagemTipo } from '@domain/produto/produto-imagem-tipo.enum';
import { ProdutoImagemVariacoes } from '@domain/produto/produto-imagem-variacoes.model';
import { ProdutoSku } from '@domain/produto/produto-sku.model';
import { AvaliacaoApi } from '@shared/avaliacao-common/avaliacao.api';
import { MainErrorObservable } from '@shared/error/main-error.observable';
import { ProdutoQueryParam } from '@shared/produto-common/produto-query-param.interface';
import { ProdutoApi } from '@shared/produto-common/produto.api';
import { MobileShareService } from '@shared/util/mobile-share-util.service';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Produto } from '../domain/produto/produto.model';

@Component({
  selector: 'crtz-produto-detalhe',
  templateUrl: './produto-detalhe.component.html',
  styleUrls: ['./produto-detalhe.component.scss']
})
export class ProdutoDetalheComponent implements OnInit, OnDestroy {

  readonly IMAGEM_TAMANHO_MEDIO = ProdutoImagemTipo.MEDIA;
  readonly IMAGEM_TAMANHO_GRANDE = ProdutoImagemTipo.ZOOM;
  private readonly MIN_DESKTOP_WIDTH = 780;

  readonly QUANTIDADE_MINIMA_PARA_EXIBIR_EQUIVALENCIAS = 2;
  readonly ID_CATEGORIA_MOVEIS = 93;

  produto: Produto | null = null;
  produtoSku: ProdutoSku | null = null;
  imagemPrincipal: ProdutoImagemVariacoes | null = null;

  hasShareFunction = false;
  toggleDetalhes = false;

  private subscriptionProdutoRequest: Subscription | null = null;
  private subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private mobileShareService: MobileShareService,
    private error$: MainErrorObservable,
    private produtoApi: ProdutoApi,
    private avaliacaoApi: AvaliacaoApi,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const queryParams = params as ProdutoQueryParam;
      this.loadProduto(queryParams);
    });

    this.hasShareFunction = this.mobileShareService.hasNativeShareFunction();
  }

  loadProduto(queryParams: ProdutoQueryParam): void {
    this.subscriptionProdutoRequest = this.produtoApi.getProduto(Number(queryParams.skuExterno))
      .pipe(switchMap(produto => {
        return produto ? this.popularResumoAvaliacao(produto) : of(produto);
      })).subscribe((produto) => {
        if (produto) {
          this.produto = produto;

          let currentProdutoSku = produto.skus.find(produtoSku => produtoSku.sku === produto?.sku);

          if (!currentProdutoSku) {
            currentProdutoSku = produto?.skus && produto?.skus[0] || null;
          }

          this.updatePageMetadata(produto, currentProdutoSku);

          this.setProdutoSku(currentProdutoSku);
        }
      });

    this.subscriptions.add(this.subscriptionProdutoRequest);
  }

  // eslint-disable-next-line
  private updatePageMetadata(produto: Produto, produtoSku: ProdutoSku | null = null): void {
    if (produto) {

      this.metaService.updateTag({ property: 'og:title', content: produto.nome });

      this.metaService.addTags([
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Cortez Móveis' },
        { name: 'description', content: 'produto.descricao' },
        { name: 'Abstract', content: 'produto.descricao' },
        { property: 'og:title', content: produto.nome },
        { property: 'og:description', content: produto.descricao },
      ]);

      this.titleService.setTitle(produto.nome);


      this.metaService.addTag({ property: 'og:image', content: 'https://imgs.casasbahia.com.br/55060824/1g.jpg?imwidth=500' });
      // if (produtoSku?.imagemPrincipal) {
      //   this.metaService.addTag({ property: 'og:image', content: produtoSku?.imagemPrincipal.MEDIA.imagem });
      // } else {
      //   // eslint-disable-next-line
      //   this.metaService.removeTag(`property='og:image'`);
      // }

    }
  }

  private popularResumoAvaliacao(produto: Produto): Observable<Produto> {
    return this.avaliacaoApi.getProdutoAvaliacoes(produto.id, 0, 0)
      .pipe(map<{ avaliacao: Avaliacao }, Produto>(({ avaliacao }) => {
        if (avaliacao.nota !== null && produto) {
          produto.avaliacao = {
            nota: avaliacao.nota,
            composicaoNota: avaliacao.composicao,
            total: avaliacao.total,
            porcentagemRecomendacao: avaliacao.porcentagemRecomendacao
          };
        }

        return produto;
      }),
        catchError(() => of(produto)));
  }

  shareLinkProduto(): void {
    this.mobileShareService.share({
      title: document.title,
      url: location.href
    }).catch(error => this.error$.next(error));
  }

  onChangeProdutoSku(skuChange: string): void {
    const produtoSkuSelecionado = this.produto?.skus.find(item => item.sku === Number(skuChange)) || null;

    this.setProdutoSku(produtoSkuSelecionado);
  }

  setProdutoSku(produtoSku: ProdutoSku | null): void {
    if (this.produto && produtoSku) {
      this.produtoSku = produtoSku;
      this.imagemPrincipal = this.produtoSku?.imagemPrincipal || null;
      this.produto.sku = produtoSku?.sku;
    }
  }

  onClickChoose(imagem: ProdutoImagemVariacoes): void {
    this.imagemPrincipal = imagem;
  }

  hasEquivalencias(produto: Produto): boolean {
    return produto?.skus?.length >= this.QUANTIDADE_MINIMA_PARA_EXIBIR_EQUIVALENCIAS;
  }

  isTipoMoveis(): boolean {
    return this.produto?.categoria?.id === this.ID_CATEGORIA_MOVEIS;
  }

  possuiInformacoesDimensoes(): boolean {
    return !!(this.produtoSku?.dimensoes?.altura
      || this.produtoSku?.dimensoes?.comprimento
      || this.produtoSku?.dimensoes?.largura
      || this.produto?.material);
  }

  isEquivalenciaSelecionada(produtoSku: ProdutoSku): true | null {
    return produtoSku === this.produtoSku ? true : null;
  }

  isLoadingProduto(): boolean {
    return !!this.subscriptionProdutoRequest && !this.subscriptionProdutoRequest?.closed;
  }

  trackByFn(index: number): number {
    return index;
  }

  isMobile(): boolean {
    return window.innerWidth < this.MIN_DESKTOP_WIDTH;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
