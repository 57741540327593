import { Component } from '@angular/core';
import { ProdutoVideo } from '@domain/produto/produto-video.model';
import { ModalableDirective } from '@shared/modal/modalable.directive';
import { Subject } from 'rxjs';
import { ModalVideoProdutoInterface } from './modal-video-produto.interface';

@Component({
  selector: 'crtz-modal-video-produto',
  templateUrl: './modal-video-produto.component.html',
  styleUrls: ['./modal-video-produto.component.scss']
})
export class ModalVideoProdutoComponent extends ModalableDirective<ModalVideoProdutoInterface, boolean> {
  response = new Subject<boolean | void>();
  video?: ProdutoVideo;

  onInjectData(data: ModalVideoProdutoInterface): void {
    this.video = data.video;
  }
}
