import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HtmlSafeModule } from '@shared/htmlsafe/html-safe.module';
import { ProdutoCommonModule } from '@shared/produto-common/produto-common.module';
import { StarRatingModule } from '@shared/star-rating/star-rating.module';
import { VideoYoutubePlayerModule } from '@shared/video-youtube-player/video-youtube-player.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NotFoundModule } from '../not-found/not-found.module';
import { DimensoesModule } from './../shared/dimensoes/dimensoes.module';
import { CauroselImagensProdutoComponent } from './imagens-produto/carousel-imagens-produto/carousel-imagens-produto.component';
// eslint-disable-next-line max-len
import { ModalCarouselImagensProdutoComponent } from './imagens-produto/modal-carousel-imagens-produto/modal-carousel-imagens-produto.component';
import { ModalVideoProdutoComponent } from './imagens-produto/modal-video-produto/modal-video-produto.component';
import { ProdutoAvaliacoesReviewModule } from './produto-avaliacoes-review/produto-avaliacoes-review.module';
import { ProdutoDetalheLoaderComponent } from './produto-detalhe-loader/produto-detalhe-loader.component';
import { ProdutoDetalheComponent } from './produto-detalhe.component';

@NgModule({
  imports: [
    CommonModule,
    HtmlSafeModule,
    ProdutoAvaliacoesReviewModule,
    VideoYoutubePlayerModule,
    DimensoesModule,
    NotFoundModule,
    ProdutoCommonModule,
    NgxSkeletonLoaderModule,
    StarRatingModule
  ],
  declarations: [
    ProdutoDetalheComponent,
    CauroselImagensProdutoComponent,
    ModalCarouselImagensProdutoComponent,
    ModalVideoProdutoComponent,
    ProdutoDetalheLoaderComponent
  ],
  exports: [
    ProdutoDetalheComponent
  ]
})
export class ProdutoDetalheModule { }
