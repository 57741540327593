<div class="item">
  <ngx-skeleton-loader [theme]="{
      height: '100%',
      'border-radius': '5px',
      outline: 'none'
    }"></ngx-skeleton-loader>
</div>

<div class="item">
  <ngx-skeleton-loader [theme]="{
      height: '100%',
      'border-radius': '5px',
      outline: 'none'
    }"></ngx-skeleton-loader>
</div>

<div class="item">
  <ngx-skeleton-loader [theme]="{
      height: '100%',
      'border-radius': '5px',
      outline: 'none'
    }"></ngx-skeleton-loader>
</div>
