<div
  *ngIf="imagemPrincipal"
  class="container"
  [class.only-carousel-full-screen-view]="showOnlyCarouselWhenFullScreen"
  [ngStyle]="{
    'width': containerWidthStyle
  }"
>
  <img
    (click)="openDetalheImagemPrincipal && abrirModalDetalheImagem()"
    class="imagem-principal"
    [class.imagem-principal--action]="openDetalheImagemPrincipal"
    [class.qa-landing-page-button-imagem-principal-click]="openDetalheImagemPrincipal"
    [src]="imagemPrincipal | produtoImagemTamanho: IMAGEM_TIPO_PRINCIPAL"
    alt="Imagem atual galeria"
    aria-hidden="true"
  />
  <div class="carousel">
    <div class="button-carousel-container button-carousel-container__left">
      <button
        type="button"
        class="qa-landing-page-button-previous-carousel-page-click"
        *ngIf="!carouselAtStart"
        (click)="previousCarouselPage()"
      >
        <svg
          class="icon to-left"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <use xlink:href="./assets/images/svg-sprite-icones.svg#arrow" />
        </svg>
      </button>
    </div>
    <div
      #carousel
      class="carousel__imagens"
    >
      <div
        #listImagens
        class="carousel__item qa-landing-page-imagem-thumb-{{indexImagem}}-click"
        *ngFor="let imagem of imagens; let indexImagem = index; trackBy: trackByFnImagens"
        role="button"
        (click)="mostrarImagemPrincipal(imagem)"
      >
        <div
          class="carousel__img-container"
          [class.carousel__img-container--selected]="isCarouselItemSelected(imagem)"
        >
          <img
            [src]="imagem | produtoImagemTamanho : tipoImagemCarousel"
            alt="Imagem galeria"
            aria-hidden="true"
          />
        </div>
      </div>

      <div
        class="carousel__item carousel__video-container qa-landing-page-video-thumb-{{indexVideo}}-click"
        (click)="abrirModalExibicaoVideo(video)"
        role="button"
        *ngFor="let video of videos; let indexVideo = index; trackBy: trackByFnVideos"
      >
        <div>
          <img
            src="assets/play.svg"
            alt="play vídeo"
            aria-hidden="true"
          >
        </div>
        <img
          [src]="createYoutubeThumb(video.videoPath)"
          alt="Imagem galeria"
          aria-hidden="true"
        />
      </div>
    </div>
    <div class="button-carousel-container button-carousel-container__right">
      <button
        type="button"
        class="qa-landing-page-button-next-carousel-page-click"
        *ngIf="!carouselAtEnd"
        (click)="nextCarouselPage()"
      >
        <svg
          class="icon to-right"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <use xlink:href="./assets/images/svg-sprite-icones.svg#arrow" />
        </svg>
      </button>
    </div>
  </div>
</div>
