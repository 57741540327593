import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileShareService } from './mobile-share-util.service';
import { NumberUtilService } from './number-util.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    NumberUtilService,
    MobileShareService
  ]
})
export class UtilModule { }
