<div class="container">
  <div>
    <img
      [width]="starWidth"
      *ngFor="let item of [].constructor(MAX_STARS); trackBy: null "
      src="assets/empty-star.svg"
    />
  </div>
  <div class="container__rating">
    <img
      [width]="starWidth"
      *ngFor="let item of [].constructor(getRating()); trackBy: null "
      src="assets/filled-star.svg"
    />
  </div>
</div>
