import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ILoggableErrorNormalized } from '@shared/error/loggable-error-normalized.interface';
import { enumTypeGuard } from '@taipescripeto/enum-type-guard';
import { ErrorConverter, IErrorNormalized } from 'ecma-error-normalizer';
import { VError } from 'ts-interface-checker/dist/util';
import { LogType } from './log-type.enum';
import { MENSAGEM_ERRO_DESCONHECIDO_APLICACAO } from './mensagens-logger.const';

@Injectable()
export class LoggerService {

  private readonly MINIMO_PARA_DOIS_DIGITOS = 10;

  private readonly LOG_COLOR_MAP: {
    [logType in LogType]: string
  } = {
      [LogType.DEPRECATED]: '',
      [LogType.FRONTEND_CRASH_ERROR]: '',
      [LogType.DEVICE_ERROR]: '',
      [LogType.BACKEND_ERROR]: '',
      [LogType.INTEGRATION_INVALID_SCHEMA]: '',
      [LogType.CONNECTION_FAILURE]: '',
      [LogType.BUSSINESS_ERROR]: 'color:#aa0000',
      [LogType.NUMERIC_INCONSISTENCY]: '',
      [LogType.FRONTEND_LOGICAL_ERROR]: '',
      [LogType.WARNING]: '',
      [LogType.INFORMATIVE]: 'color:#ffffff',
      [LogType.HIGHTLIGHTED]: 'color:#bef441'
    };

  private readonly WARNING_LEVEL = [
    LogType.WARNING,
    LogType.DEPRECATED
  ];

  private readonly ERROR_LEVEL = [
    LogType.FRONTEND_CRASH_ERROR,
    LogType.BACKEND_ERROR,
    LogType.INTEGRATION_INVALID_SCHEMA,
    LogType.CONNECTION_FAILURE,
    LogType.NUMERIC_INCONSISTENCY
  ];

  constructor(
    private errorConverter: ErrorConverter
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logHttp(logType: LogType, msg: string, httpRequest: { url: string, method: string, body: any }): void {
    const serializedObject = JSON.stringify(httpRequest.body);
    const log = `[${httpRequest.method}] ${httpRequest.url} - ${msg}, body = ${serializedObject}`;
    this.log(logType, log);
  }

  serializeErrorObject(error: Error): string {
    return JSON.stringify({
      name: error.name,
      message: error.message,
      stack: error.stack
    });
  }

  log(logType: LogType, msg: string, ...args: unknown[]): void {
    const logColor = this.LOG_COLOR_MAP[logType];
    const additionalInfo: string[] = [this.getFormattedCurrentDate(), logType, '%c' + msg];
    let consoleArguments: [...unknown[]] = [additionalInfo.join(' ')];
    consoleArguments.push(logColor);

    if (args) {
      consoleArguments = consoleArguments.concat(args);
    }

    const consoleApplyableArguments = consoleArguments as [unknown?, ...unknown[]];
    if (this.ERROR_LEVEL.includes(logType)) {
      // eslint-disable-next-line
      console.error.apply(console, consoleApplyableArguments);
    } else if (this.WARNING_LEVEL.includes(logType)) {
      // eslint-disable-next-line
      console.warn.apply(console, consoleApplyableArguments);
    } else {
      // eslint-disable-next-line
      console.info.apply(console, consoleApplyableArguments);
    }
  }

  /**
     * Normaliza um erro e imprime uma mensagem de log correspondente.
     * Decidimos não quebrar mais a aplicação quando houver um erro de schema.
     * Sendo assim, iremos simular a quebra da aplicação e lançar um warning,
     * de forma que, se o defeito gerado por parte da API não for significativamente grave,
     * não terá efeitos visíveis. A conversão por as never no retorno da função
     * simula esta quebra de aplicação.
     *
     * @param error Erro a ser normalizado
     * @returns Indica que fluxo não continuará ao chamar essa função
     */
  logInvalidSchema(error: VError | Error | unknown): never {
    this.logNormalizeError(error);
    //  Decidimos não quebrar mais a aplicação quando houver um erro de schema.
    //  Iremos simular a quebra da aplicação e lançar um warning, de forma que, se
    //  o defeito gerado por parte da API não for significativamente grave, não
    //  terá efeitos visíveis.
    //  A conversão por `as` abaixo está simulando uma quebra de aplicação

    if (!environment.isProduction) {
      throw error;
    } else {
      return void (0) as never;
    }
  }

  /**
   * Normaliza um erro e imprime a mensagem de log correspondente
   *
   * @param error Erro a ser normalizado de um tipo não especificado
   */
  logNormalizeError(error: unknown): void {
    const errorNormalized = this.errorConverter.create(error);

    if (!errorNormalized) {
      let logMessage = MENSAGEM_ERRO_DESCONHECIDO_APLICACAO;

      if (error instanceof Function) {
        logMessage += `instanceof "${error.name}"`;
      } else if (error instanceof Object) {
        logMessage += `instanceof "${error.constructor.name}"`;
      } else {
        logMessage += `typeof "${typeof error}"`;
      }

      this.log(LogType.FRONTEND_CRASH_ERROR, logMessage, error);
      return;
    }

    let logType = LogType.FRONTEND_CRASH_ERROR;
    if (this.isLoggableErrorNormalized(errorNormalized)) {
      logType = errorNormalized.logType;
    }

    const technicalMessage = errorNormalized.technicalMessages.join(' ');
    const usuerMessage = errorNormalized.messages.join(' ');
    this.log(logType,
      `Technical Messages: "${technicalMessage}"`,
      `User message: "${usuerMessage}"`,
      errorNormalized);
  }

  /**
    * Identifica se o erro passado por parâmetro é um erro normalizado
    * e, caso seja, infere seu tipo como ILoggableErrorNormalized.
    *
    * @param errorNormalized Erro a ser verificado
    * @returns Type predicate que infere o tipo como ILoggableErrorNormalized
    */
  private isLoggableErrorNormalized(
    errorNormalized: IErrorNormalized | ILoggableErrorNormalized | null
  ): errorNormalized is ILoggableErrorNormalized {
    if (!errorNormalized) {
      return false;
    }

    const possibleLoggable: { logType?: string } = Object(errorNormalized);
    if (possibleLoggable.logType && enumTypeGuard(possibleLoggable.logType, LogType)) {
      return true;
    }
    return false;
  }

  private getFormattedCurrentDate(): string {
    const date = new Date();
    let d: string | number = date.getDate();
    d = d < this.MINIMO_PARA_DOIS_DIGITOS ? '0' + d : d;

    let m: string | number = date.getMonth() + 1;
    m = m < this.MINIMO_PARA_DOIS_DIGITOS ? '0' + m : m;

    const Y = date.getFullYear();

    let H: string | number = date.getHours();
    H = H < this.MINIMO_PARA_DOIS_DIGITOS ? '0' + H : H;

    let i: string | number = date.getMinutes();
    i = i < this.MINIMO_PARA_DOIS_DIGITOS ? '0' + i : i;

    let s: string | number = date.getSeconds();
    s = s < this.MINIMO_PARA_DOIS_DIGITOS ? '0' + s : s;

    return `[${d}/${m}/${Y} ${H}:${i}:${s}]`;
  }
}
