<div
  class="container"
  title="Carregando produto"
>
  <div class="produto-detalhes">
    <div class="carousel">
      <ngx-skeleton-loader [theme]="{
      height: '100%',
      'border-radius': '5px',
      outline: 'none'
    }"></ngx-skeleton-loader>
    </div>

    <div class="detalhe">
      <div class="item">
        <ngx-skeleton-loader [theme]="{
      height: '100%',
      'border-radius': '5px',
      outline: 'none'
    }"></ngx-skeleton-loader>
      </div>

      <div class="item">
        <ngx-skeleton-loader [theme]="{
      height: '100%',
      'border-radius': '5px',
      outline: 'none'
    }"></ngx-skeleton-loader>
      </div>

      <div class="item">
        <ngx-skeleton-loader [theme]="{
      height: '100%',
      'border-radius': '5px',
      outline: 'none'
    }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <div class="avaliacoes">
    <ngx-skeleton-loader [theme]="{
      height: '100%',
      'border-radius': '5px',
      outline: 'none'
    }"></ngx-skeleton-loader>
  </div>
</div>
