import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvaliacaoCommonModule } from '@shared/avaliacao-common/avaliacao-common.module';
import { ProdutoImagemTamanhoPipe } from './produto-imagem-tamanho.pipe';
import { ProdutoApi } from './produto.api';
import { ProdutoChecker } from './produto.checker';

@NgModule({
  imports: [
    CommonModule,
    AvaliacaoCommonModule
  ],
  declarations: [
    ProdutoImagemTamanhoPipe
  ],
  providers: [
    ProdutoApi,
    ProdutoChecker
  ],
  exports: [
    ProdutoImagemTamanhoPipe
  ]
})
export class ProdutoCommonModule { }
