<header
  class="main-header"
  [class.is-cb]="bandeira === BANDEIRA_CASAS_BAHIA"
  [class.is-pf]="bandeira === BANDEIRA_PONTO"
  [class.is-ex]="bandeira === BANDEIRA_EXTRA"
>
  <img
    src="assets/images/logo.svg"
    alt="Logo bandeira"
    aria-hidden="true"
  >
  <meta
    property="og:title"
    content="vilmar title"
  >
</header>
