import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProdutoDetalheComponent } from './produto-detalhe/produto-detalhe.component';
import { ProdutoCommonModule } from './shared/produto-common/produto-common.module';

@NgModule({
  imports: [
    ProdutoCommonModule,
    RouterModule.forRoot(AppRoutingModule.routes)
  ],
  exports: [
    ProdutoCommonModule,
    RouterModule
  ]
})
export class AppRoutingModule {
  static readonly routes: Routes = [
    {
      path: '',
      component: ProdutoDetalheComponent,
    }
  ];
}
