import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DeserializedDataValidationService } from './deserialized-data-validation.service';

/**
 * Módulo responsável pelo serviços compartilhados de validação de dados
 */
@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    DeserializedDataValidationService
  ]
})
export class CommonValidationModule { }
