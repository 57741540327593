<ng-container *ngIf="avaliacao">
  <div class="avaliacao">

    <div class="avaliacao__nome">
      <h3>{{avaliacao.nome}}</h3>
    </div>

    <div class="avaliacao__content">

      <div class="avaliacao__content__data">
        <p>{{avaliacao.data}}</p>
        <crtz-star-rating
          [rating]="avaliacao?.nota"
          [starWidth]="12"
        ></crtz-star-rating>
      </div>

      <div class="avaliacao__content__descricao">
        <p>{{avaliacao.texto}}</p>
      </div>

    </div>
  </div>
</ng-container>
