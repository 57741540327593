import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ProdutoAvaliacoes } from '@domain/produto/produto-avaliacoes.model';

@Component({
  selector: 'crtz-avaliacao-nota',
  templateUrl: './avaliacao-nota.component.html',
  styleUrls: ['./avaliacao-nota.component.scss']
})
export class AvaliacaoNotaComponent {
  showNotas = false;
  isElementRightSideOfScreen = false;

  @Input()
  avaliacao: ProdutoAvaliacoes | null = null;

  @ViewChild('container')
  container?: ElementRef<HTMLDivElement>;

  toggleOpenMenu(): void {
    this.showNotas = !this.showNotas;
    this.isElementRightSideOfScreen = this.getIsElementRightSideOfScreen();
  }

  getIsElementRightSideOfScreen(): boolean {
    if (!this.container) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const halfViewPortWidth = window.innerWidth / 2;
    const { x: xElementPosition } = this.container.nativeElement.getBoundingClientRect();

    return xElementPosition > halfViewPortWidth;
  }

  trackByFn(index: number): number {
    return index;
  }

}
