<section>
  <div class="header">
    <span>{{getDescricaoHeader()}}</span>

    <button
      type="button"
      (click)="close()"
    >
      <em class="icon icon-close"></em>
    </button>
  </div>

  <ng-container *ngIf="carouselData">
    <crtz-carousel-imagens-produto
      [imagens]="carouselData.imagens"
      [imagemPrincipal]="carouselData.imagemPrincipal"
      [carouselItemWidth]="90"
      [showOnlyCarouselWhenFullScreen]="true"
      (onImagemScrollSelecionada)="onImagemScrollSelecionada($event)"
    ></crtz-carousel-imagens-produto>
  </ng-container>
</section>
