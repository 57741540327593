import { Component, Input } from '@angular/core';

@Component({
  selector: 'crtz-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent {
  readonly MAX_STARS = 5;
  private readonly STAR_WIDTH_DEFAULT = 24;

  @Input()
  rating?: string;

  @Input()
  starWidth = this.STAR_WIDTH_DEFAULT;

  getRating(): number {
    return Math.min(this.MAX_STARS, Number(this.rating) || 0);
  }

}
