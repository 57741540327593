import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '@environment';

/**
 * Este pipe valida o html entregue por pessoas que cadastraram o produto
 * e também renderiza alguma imagem que eles tenham escolhido para ser
 * apresentada em conjunto a descrição do produto.
 */
@Pipe({
  name: 'htmlSafe'
})
export class HtmlSafePipe implements PipeTransform {

  variaveisTemplate: {
    [variavel: string]: string
  } = {
      DominioImagem: environment.imagemServer
    };

  constructor(
    protected sanitizer: DomSanitizer
  ) { }

  transform(unsafeHtml?: string): SafeHtml | string | null {
    if (!unsafeHtml) {
      return '';
    }

    unsafeHtml = this.aplicarVariaveisAoTemplate(unsafeHtml);
    return this.sanitizer.sanitize(SecurityContext.HTML, unsafeHtml);
  }

  private aplicarVariaveisAoTemplate(html: string): string {
    Object.keys(this.variaveisTemplate).forEach(variavel => {
      html = this.aplicarVariavelAoTemplate(html, variavel, this.getVariavelValor(variavel));
    });

    return html;
  }

  private getVariavelValor(variavel: string): string {
    return this.variaveisTemplate[variavel] || '';
  }

  private aplicarVariavelAoTemplate(
    template: string, variavel: string, valor: string
  ): string {
    const regex = this.montarRegex(variavel);
    return template.replace(regex, valor);
  }

  private montarRegex(variavelNome: string): RegExp {
    return new RegExp(`@${variavelNome}@`, 'g');
  }
}
