import { Bandeira } from './../app/domain/bandeira.enum';
import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  isProduction: true,

  bandeira: Bandeira.CASAS_BAHIA,

  server: '${API_BASE_URL}',

  imagemServer: '${API_IMAGEM_URL_CB}'
};
