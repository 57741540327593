import { Injectable } from '@angular/core';
import { IErrorNormalized } from 'ecma-error-normalizer';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  isWarning(error: IErrorNormalized): boolean {
    return error.type === 'warning';
  }

  isError(error: IErrorNormalized): boolean {
    return error.type !== 'warning';
  }
}
