import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SoftwareVersionApi } from './software-version.api';

@Injectable()
export class SoftwareVersionService {

  constructor(
    private softwareVersionApi: SoftwareVersionApi
  ) { }

  getVersionFromPackageJson(): Observable<string> {
    return this.softwareVersionApi
      .getPackageJson()
      .pipe(map(packageJson => packageJson.version));
  }
}
