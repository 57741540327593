import { Injectable } from '@angular/core';
import { Avaliacao } from '@domain/avaliacao/avaliacao.model';
import { DeserializedDataValidationService } from '@shared/common-validation/deserialized-data-validation.service';
import { array, createCheckers, iface, ITypeSuite, union } from 'ts-interface-checker';

@Injectable()
export class AvaliacaoChecker {
  private Avaliacao = iface([], {
    nota: union('number', 'null'),
    pagina: 'number',
    total: 'number',
    porcentagemRecomendacao: 'number',
    composicao: array('AvaliacaoComposicao'),
    avaliacoesUsuario: array('AvaliacaoUsuario')
  });

  private AvaliacaoComposicao = iface([], {
    nota: 'number',
    porcentagem: 'number'
  });

  private AvaliacaoUsuario = iface([], {
    id: 'number',
    nome: 'string',
    texto: 'string',
    nota: 'number',
    data: 'string'
  });

  private validations: ITypeSuite = {
    Avaliacao: this.Avaliacao,
    AvaliacaoComposicao: this.AvaliacaoComposicao,
    AvaliacaoUsuario: this.AvaliacaoUsuario
  };

  private checker = createCheckers(this.validations);

  constructor(
    private deserializedDataValidationService: DeserializedDataValidationService
  ) { }

  validateAvaliacao(wrapper: { avaliacao: Avaliacao }): { avaliacao: Avaliacao } {
    const checker = this.checker.Avaliacao;

    this.deserializedDataValidationService.checker(wrapper.avaliacao, checker);

    return wrapper;
  }
}
