import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ProdutoImagemTipo } from '@domain/produto/produto-imagem-tipo.enum';
import { ProdutoImagemVariacoes } from '@domain/produto/produto-imagem-variacoes.model';
import { ProdutoImagem } from '@domain/produto/produto-imagem.model';
import { ProdutoVideo } from '@domain/produto/produto-video.model';
import { ModalService } from '@shared/modal/modal.service';
import { ProdutoImagemTamanhoPipe } from '@shared/produto-common/produto-imagem-tamanho.pipe';
import { nextTick } from '@shared/tick.function';
import { Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ModalCarouselImagensProdutoComponent } from '../modal-carousel-imagens-produto/modal-carousel-imagens-produto.component';
import { ModalVideoProdutoComponent } from '../modal-video-produto/modal-video-produto.component';

@Component({
  selector: 'crtz-carousel-imagens-produto',
  templateUrl: './carousel-imagens-produto.component.html',
  styleUrls: ['./carousel-imagens-produto.component.scss'],
  providers: [
    ProdutoImagemTamanhoPipe
  ]
})
export class CauroselImagensProdutoComponent implements AfterViewInit, OnDestroy {
  readonly IMAGEM_TIPO_PRINCIPAL = ProdutoImagemTipo.PRINCIPAL;
  readonly IMAGEM_TIPO_CAROUSEL = ProdutoImagemTipo.MEDIA;
  private readonly CONTAINER_WIDTH_DEFAULT = 690;
  private readonly CAROUSEL_ITEM_WIDTH_DEFAULT = 80;
  private readonly MIN_DESKTOP_WIDTH = 780;
  private subscriptions = new Subscription();
  private subscriptionsCarousel = new Subscription();

  carouselAtStart?: boolean;
  carouselAtEnd?: boolean;

  @ViewChild('carousel')
  carouselRef?: ElementRef<HTMLDivElement>;

  @ViewChildren('listImagens')
  listImagens?: QueryList<{}>;

  @Input()
  imagens: ProdutoImagemVariacoes[] = [];

  @Input()
  videos: ProdutoVideo[] = [];

  @Input()
  imagemPrincipal?: ProdutoImagemVariacoes;

  @Input()
  openDetalheImagemPrincipal = false;

  @Input()
  containerWidth = this.CONTAINER_WIDTH_DEFAULT;

  @Input()
  carouselItemWidth = this.CAROUSEL_ITEM_WIDTH_DEFAULT;

  @Input()
  showOnlyCarouselWhenFullScreen = false;

  @Output()
  onImagemScrollSelecionada = new EventEmitter<number>();

  @HostListener('window:resize', ['$event'])
  onResizeWindow(): void {
    this.configureCarouselImages();
  }

  constructor(
    private produtoImagemTamanhoPipe: ProdutoImagemTamanhoPipe,
    private modalService: ModalService
  ) { }

  ngAfterViewInit(): void {
    this.scrollToSelectedImage();
    this.configCarouselIntersectionObservers();
    this.configureCarouselImages();
    this.configureListenerImageSelected();
    this.configureListenerOnImagensChange();
  }

  scrollToSelectedImage(): void {
    const carouselElement = this.carouselRef?.nativeElement;

    if (!carouselElement || !this.imagemPrincipal) {
      return;
    }

    const srcImageCaurosel = this.produtoImagemTamanhoPipe.transform(this.imagemPrincipal, this.tipoImagemCarousel);
    const cauroselSelectedImage = carouselElement.querySelector(`[src=\'${srcImageCaurosel}\']`);

    if (cauroselSelectedImage) {
      /*
        Por algum motivo a chamada no início da renderização
        do scrollIntoView está fazendo com que, esporadicamente,
        o scroll seja colocado no final, mesmo com a imagem selecionada
        estando no início.
       */
      // eslint-disable-next-line ban/ban
      void nextTick().then(() => {
        cauroselSelectedImage?.scrollIntoView({
          inline: 'center',
          block: 'nearest'
        });
      });
    }
  }

  configCarouselIntersectionObservers(): void {
    const carouselElement = this.carouselRef?.nativeElement;

    if (!carouselElement) {
      return;
    }

    this.subscriptionsCarousel.unsubscribe();
    this.subscriptionsCarousel = new Subscription();

    const firstCarouselEl = carouselElement.firstElementChild;
    const lastCarouselEl = carouselElement.lastElementChild;

    if (firstCarouselEl && lastCarouselEl) {
      this.subscriptionsCarousel.add(this.registerIntersectionObserver(firstCarouselEl)
        .subscribe(({ isIntersecting }) => {
          this.carouselAtStart = isIntersecting;
        }));

      this.subscriptionsCarousel.add(this.registerIntersectionObserver(lastCarouselEl)
        .subscribe(({ isIntersecting }) => {
          this.carouselAtEnd = isIntersecting;
        }));
    }
  }

  private registerIntersectionObserver(targetElement: Element): Observable<{
    isIntersecting: boolean
  }> {
    return new Observable<IntersectionObserverEntry[]>((observer => {
      const intersectionObserver = new IntersectionObserver((entrys: IntersectionObserverEntry[]) => {
        observer.next(entrys);
      }, {
        root: this.carouselRef?.nativeElement,
        threshold: 0.5
      });

      intersectionObserver.observe(targetElement);

      return () => {
        intersectionObserver.disconnect();
      };
    })).pipe(
      mergeMap((entries: IntersectionObserverEntry[]) => entries),
      map(entry => ({
        isIntersecting: entry.isIntersecting
      }))
    );
  }

  mostrarImagemPrincipal(imagemVariacao: ProdutoImagemVariacoes): void {
    this.imagemPrincipal = imagemVariacao;
  }

  nextCarouselPage(): void {
    const carouselElement = this.carouselRef?.nativeElement;

    if (!carouselElement) {
      return;
    }

    carouselElement.scrollBy({
      left: this.carouselItemWidth,
      behavior: 'smooth'
    });
  }

  previousCarouselPage(): void {
    const carouselElement = this.carouselRef?.nativeElement;

    if (!carouselElement) {
      return;
    }

    carouselElement.scrollBy({
      left: -this.carouselItemWidth,
      behavior: 'smooth'
    });
  }

  configureCarouselImages(): void {
    const carouselElement = this.carouselRef?.nativeElement;

    if (!carouselElement) {
      return;
    }

    const carouselWidth = carouselElement.clientWidth;
    const carouselImagesCount = Math.floor(carouselWidth / this.carouselItemWidth);

    carouselElement.style.setProperty('--image-carousel-count', `${carouselImagesCount}`);
  }

  abrirModalDetalheImagem(): void {
    this.modalService
      .createModal(ModalCarouselImagensProdutoComponent)
      .setData({
        imagens: this.imagens || [],
        imagemPrincipal: this.imagemPrincipal
      })
      .setRootCssClasses(['modal-hidden-default-header', 'full-screen-desktop'])
      .build();
  }

  abrirModalExibicaoVideo(video: ProdutoVideo): void {
    this.modalService
      .createModal(ModalVideoProdutoComponent)
      .setData({
        video
      })
      .setRootCssClasses(['modal-hidden-default-header', 'full-screen-desktop'])
      .build();
  }

  configureListenerImageSelected(): void {
    const carouselElement = this.carouselRef?.nativeElement;

    if (!carouselElement || !this.imagemPrincipal) {
      return;
    }

    this.imagens.forEach((_, index) => {
      const carouselEl = carouselElement.children.item(index);

      if (!carouselEl) {
        return;
      }

      this.subscriptions.add(this.registerIntersectionObserver(carouselEl).subscribe(({ isIntersecting }) => {
        if (isIntersecting) {
          this.onImagemScrollSelecionada.emit(index);
        }
      }));
    });
  }

  configureListenerOnImagensChange(): void {
    this.subscriptions.add(this.listImagens?.changes.subscribe(() => {
      this.configCarouselIntersectionObservers();
    }));
  }

  isCarouselItemSelected(imagem: ProdutoImagemVariacoes): boolean {
    return imagem.PRINCIPAL.imagem === this.imagemPrincipal?.PRINCIPAL.imagem;
  }

  get tipoImagemCarousel(): ProdutoImagemTipo {
    return this.showOnlyCarouselWhenFullScreen ? this.IMAGEM_TIPO_PRINCIPAL : this.IMAGEM_TIPO_CAROUSEL;
  }

  get containerWidthStyle(): string {
    return this.isMobile() || this.showOnlyCarouselWhenFullScreen ? 'auto' : this.containerWidth + 'px';
  }

  private isMobile(): boolean {
    return window.innerWidth < this.MIN_DESKTOP_WIDTH;
  }

  createYoutubeThumb(videoPath: string): string {
    return `https://img.youtube.com/vi/${videoPath}/hqdefault.jpg`;
  }

  trackByFnImagens(index: number, produtoImagem: ProdutoImagem): string {
    return `${index}-${produtoImagem.imagem}`;
  }

  trackByFnVideos(index: number, produtoVideo: ProdutoVideo): string {
    return `${index}-${produtoVideo.videoPath}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsCarousel.unsubscribe();
  }
}
