import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { ProdutoVideo } from '@domain/produto/produto-video.model';

@Component({
  selector: 'crtz-video-youtube-player',
  templateUrl: './video-youtube-player.component.html',
  styleUrls: ['./video-youtube-player.component.scss']
})
export class VideoYoutubePlayerComponent {
  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  @Input()
  video: ProdutoVideo | null = null;

  sanitizedPath(): SafeValue | null {
    const video = this.video;
    if (video) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(this.createYoutubeLink(video.videoPath));
    }

    return null;
  }

  createYoutubeLink(videoPath: string): string {
    return `https://www.youtube.com/embed/${videoPath}`;
  }
}
