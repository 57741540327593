import { NgModule } from '@angular/core';
import { HtmlSafePipe } from './html-safe.pipe';

@NgModule({
  declarations: [
    HtmlSafePipe
  ],
  exports: [
    HtmlSafePipe
  ]
})
export class HtmlSafeModule { }
