import { HttpResponse } from '@angular/common/http';

export class InvalidResultSetException extends Error {
  constructor(
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    public readonly request: HttpResponse<unknown>
  ) {
    super(`[InvalidResultSetException] "${JSON.stringify(request.body)}"`);
  }
}
