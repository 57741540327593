<div class="nota">
  <div class="avaliacao">
    <span class="avaliacao__nota">{{avaliacao?.nota | produtoNota}}</span>

    <div class="avaliacao__estrelas">
      <crtz-star-rating [rating]="avaliacao?.nota"></crtz-star-rating>
      <span class="avaliacao__estrelas__quantidade">({{avaliacao | produtoQuantidadeAvaliacoes}})</span>
    </div>

    <svg
      class="icon {{showNotas ? 'to-up' : 'to-bottom'}}"
      viewBox="0 0 24 24"
      aria-hidden="true"
      (click)="toggleOpenMenu()"
    >
      <use xlink:href="./assets/images/svg-sprite-icones.svg#arrow" />
    </svg>

  </div>

  <p class="porcentagem-recomendacao"><strong>{{avaliacao?.porcentagemRecomendacao}}%</strong> dos clientes recomendaram
    este produto</p>

  <div
    class="container__content"
    [class.container__content--show-menu]="showNotas"
    [class.container__content--is-right-side]="isElementRightSideOfScreen"
  >
    <ng-container *ngIf="avaliacao?.composicaoNota?.length">
      <div class="composicao">
        <h3>Composição da nota</h3>
        <span class="numero-avaliacoes">({{avaliacao | produtoQuantidadeAvaliacoes}}) </span>
        <ul>
          <li
            *ngFor="let rating of avaliacao?.composicaoNota; trackBy: trackByFn"
            class="notas"
          >

            <crtz-star-rating
              [rating]="rating.nota"
              [starWidth]="12"
            ></crtz-star-rating>

            <span>{{rating.porcentagem}}%</span>
          </li>
        </ul>
      </div>
    </ng-container>

  </div>

</div>
