import { Injectable } from '@angular/core';
import { IErrorNormalized } from 'ecma-error-normalizer';
import { Subject } from 'rxjs';

@Injectable()
export class MainErrorObservable extends Subject<IErrorNormalized | null> {

  private static instance: MainErrorObservable | null = null;

  constructor() {
    super();

    if (!MainErrorObservable.instance) {
      MainErrorObservable.instance = this;
    }

    return MainErrorObservable.instance;
  }
}
