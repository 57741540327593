import { Injectable } from '@angular/core';
import { LogType } from '../logger/log-type.enum';
import { LoggerService } from '../logger/logger.service';

@Injectable()
export class MobileShareService {

  constructor(
    private logger: LoggerService
  ) { }

  async share(content: ShareData): Promise<void> {
    if (!navigator.share) {
      this.logger.log(LogType.FRONTEND_LOGICAL_ERROR,
        'O compartilhamento não foi gerado pela inexistência da função navigator.share');
      return Promise.resolve();
    }

    return navigator.share(content);
  }

  hasNativeShareFunction(): boolean {
    return !!navigator.share;
  }
}
