import { Component } from '@angular/core';
import { ModalableDirective } from '@shared/modal/modalable.directive';
import { ProdutoImagemTamanhoPipe } from '@shared/produto-common/produto-imagem-tamanho.pipe';
import { Subject } from 'rxjs';
import { ModalCarouselImagensProdutoInterface } from './modal-carousel-imagens-produto.interface';

@Component({
  selector: 'crtz-modal-imagens-produto',
  templateUrl: './modal-carousel-imagens-produto.component.html',
  styleUrls: ['./modal-carousel-imagens-produto.component.scss'],
  providers: [
    ProdutoImagemTamanhoPipe
  ]
})
export class ModalCarouselImagensProdutoComponent extends ModalableDirective<ModalCarouselImagensProdutoInterface, boolean> {
  response = new Subject<boolean | void>();
  carouselData?: ModalCarouselImagensProdutoInterface;
  indexImagemSelecionada = 1;

  onInjectData(data: ModalCarouselImagensProdutoInterface): void {
    this.carouselData = data;
  }

  getDescricaoHeader(): string {
    return `${this.indexImagemSelecionada + 1}/${this.carouselData?.imagens.length}`;
  }

  onImagemScrollSelecionada(indexImagemSelecionada: number): void {
    this.indexImagemSelecionada = indexImagemSelecionada;
  }
}
