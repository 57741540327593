import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HtmlSafeModule } from '@shared/htmlsafe/html-safe.module';
import { VideoYoutubePlayerComponent } from './video-youtube-player.component';

@NgModule({
  declarations: [
    VideoYoutubePlayerComponent
  ],
  imports: [
    CommonModule,
    HtmlSafeModule
  ],
  exports: [
    VideoYoutubePlayerComponent
  ]
})
export class VideoYoutubePlayerModule { }
