import { Pipe, PipeTransform } from '@angular/core';
import { Calc } from 'calc-js';

@Pipe({
  name: 'produtoNota'
})
export class ProdutoNotaPipe implements PipeTransform {

  private readonly BASE_DECIMAL = 10;

  transform(nota: string | number | null): string {
    if (!nota) {
      return '0.0';
    }

    nota = String(nota);
    nota = Number(nota.replace(',', '.'));
    nota = String(nota);
    nota = new Calc(Number(nota)).multiply(this.BASE_DECIMAL).finish();
    nota = Math.floor(nota);

    return String(nota).replace(/(\d){1}(\d){1}/, '$1.$2');
  }

}
