import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { LogType } from '@shared/logger/log-type.enum';
import { LoggerService } from '@shared/logger/logger.service';
import { SoftwareVersionService } from '@shared/software-version/software-version.service';
import { Calc } from 'calc-js';
import { IErrorNormalized } from 'ecma-error-normalizer';
import { Subscription } from 'rxjs';
import { MainErrorObservable } from './shared/error/main-error.observable';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  softwareVersion?: string;
  error: IErrorNormalized | null = null;

  subscription = new Subscription();

  @HostBinding('attr.pdp-figital-version')
  get softwareVersionAttr(): string {
    return this.softwareVersion || '';
  }

  constructor(
    private error$: MainErrorObservable,
    private logger: LoggerService,
    private softwareVersionService: SoftwareVersionService
  ) { }

  ngOnInit(): void {
    this.bindMainError();
    this.configureCalc();
    this.loadSoftwareVersion();
  }

  private bindMainError(): void {
    this.subscription.add(this.error$.subscribe(
      error => this.updateErrorObject(error),
      error => this.updateErrorObject(error)
    ));
  }

  private updateErrorObject(error: IErrorNormalized | null): void {
    scrollTo(0, 0);
    this.error = error;
  }

  private configureCalc(): void {
    Calc.configure({
      thrownStrategy: 'emit-event'
    });

    Calc.onError(error => this.logger.log(
      LogType.NUMERIC_INCONSISTENCY, 'calc-js error was emitted', error
    ));
  }

  loadSoftwareVersion(): void {
    this.softwareVersionService.getVersionFromPackageJson()
      .subscribe(version => this.softwareVersion = version);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
