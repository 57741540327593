import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StarRatingModule } from '@shared/star-rating/star-rating.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AvaliacaoNotaComponent } from './avaliacao-nota/avaliacao-nota.component';
import { AvaliacaoReviewLoaderComponent } from './avaliacao-review-loader/avaliacao-review-loader.component';
import { AvaliacaoReviewModule } from './avaliacao-review/avaliacao-review.module';
import { ProdutoAvaliacoesReviewComponent } from './produto-avaliacoes-review.component';
import { ProdutoNotaPipe } from './produto-nota.pipe';
import { ProdutoQuantidadeAvaliacoesPipe } from './produto-quantidade-avaliacoes.pipe';

@NgModule({
  imports: [
    CommonModule,
    AvaliacaoReviewModule,
    StarRatingModule,
    NgxSkeletonLoaderModule
  ],
  declarations: [
    ProdutoAvaliacoesReviewComponent,
    ProdutoQuantidadeAvaliacoesPipe,
    ProdutoNotaPipe,
    AvaliacaoNotaComponent,
    AvaliacaoReviewLoaderComponent
  ],
  exports: [
    ProdutoAvaliacoesReviewComponent
  ]
})
export class ProdutoAvaliacoesReviewModule { }
